import React from "react";
import { AppContext } from "./AppContext";
import { useGlobalState } from "./useGlobalState";

export const StateProvider = ({ children }) => {
  const store = useGlobalState();
//   console.log("Store:",store);

  return (
     <AppContext.Provider value={store}>
        {children}
     </AppContext.Provider>
  )
};
