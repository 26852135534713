
import { AutoComplete, Form, Input } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import useSWR from "swr";
import axios from "axios";

export const AACContext = React.createContext(null);

const useAACGlobalState = () => {
    const controlledInputs = useRef({});

    const registerControlledInput = (group, inputId) => {
        if (!controlledInputs.current[group]) {
            controlledInputs.current[group] = [];
        }
        //make sure the input is not already registered
        if (controlledInputs.current[group].indexOf(inputId) === -1) {
            controlledInputs.current[group].push(inputId);
        }
    }

    const unregisterControlledInput = (group, inputId) => {
        if (controlledInputs.current[group]) {
            const index = controlledInputs.current[group].indexOf(inputId);
            if (index !== -1) {
                controlledInputs.current[group].splice(index, 1);
            }
        }
    }

    const getControlledInputs = (group) => {
        return controlledInputs.current[group] ?? [];
    }

    return {
        registerControlledInput,
        unregisterControlledInput,
        getControlledInputs,
    }

}



export const AACStateProvider = ({ children }) => {
    const store = useAACGlobalState();
    //   console.log("Store:",store);

    return (
        <AACContext.Provider value={store}>
            {children}
        </AACContext.Provider>
    )
};

export const useAACStore = () => {
    const store = useContext(AACContext);
    return store;
};


const useRawOptions = (url, queryData) => {
    
    const fetcher = ([url, data]) => {
        console.log("fetcher: ", url, data);
        if ((data === undefined)||Object.keys(data).length === 0) {
            return Promise.resolve([]);
        }  
        return axios.post(url, data, 
            {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
            }            
            ).then((res) => {
            console.log("res: ", res);
            if (res.status === 200) {
                // window.location.href = res.data.redirect;
                //options loaded
                return res.data?.data ?? [];
            }
        }).catch((err) => {
            console.log("err: ", err);
            // message.error("Něco se pokazilo: \n" + err);
        })
    }

//    const encoded = encodeURIComponent(JSON.stringify(queryData));


    const { data, error } = useSWR([url, queryData], fetcher, {
        // refreshInterval: 10,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateOnMount: false,
        keepPreviousData: true,
    });


    return {
        options: data,
        error,
    }
}

        
    




export const AACInput = ({id, name, group, url, form, ...props }) => {
    const {registerControlledInput, unregisterControlledInput, getControlledInputs} = useAACStore();
    // const [form] = Form.useFormInstance();
    group = group || 'default';
    const ref = useRef(null);
    const refInput = useRef(null);

    const inputId = name;
    // console.log("AACInput: ", inputId)

    const queryUrl = url + (url.indexOf('?') === -1 ? '?' : '&') + 'field='+inputId+'&format=json';

    const [qData, setQData] = useState({});
    const [inFocus, setInFocus] = useState(false);
    const [missingNumber, setMissingNumber] = useState(false);
    const [lastSelected, setLastSelected] = useState(false);
    const {options:rawOptions, error} = useRawOptions(queryUrl, qData);
    const focusRef = useRef(false); 
    const allowCloseRef = useRef(true);


    useEffect(() => {
        registerControlledInput(group, inputId );
        return () => unregisterControlledInput(group, inputId);
    }, [inputId, group]);


    const handleSearch = () => {
        setLastSelected(null);
        const fields = getControlledInputs(group);
        let data= {
            'field': inputId,
        };
        fields.forEach((field) => {
            data[field] = form.getFieldValue(field);
        });
        setQData(data);
    }

    const handleSelect = (value, option) => {
        // console.log("handleSelect: ", value, option);
        const d = option.data;
        let val = d[option.field];
        if (option.field=='street') {
            if (d['city']) {
                form.setFieldValue('city', d['city']);
            }
            if (d['zip']) {
                form.setFieldValue('zip', d['zip']);
            }
            if (!d['number']) {
                val += ' ';
            }
            setLastSelected(true);
            setMissingNumber(!d['number']);
            setQData({...qData, 'street': val});
            allowCloseRef.current = false;
            setTimeout(() => {
                allowCloseRef.current = true;
            }, 100);

        }
        form.setFieldValue(option.field, val);
    }

    const handleVisibleChange = (open) => {
        if ((!open)&&(allowCloseRef.current)) {
            setLastSelected(false);
        }
    }            

    const options = rawOptions?.map((option) => {
        const distStr = option['district'] ? option['district'] : '';
        const showDist = (distStr.length>0) && (option?.[name]?.indexOf(distStr) === -1);
        return {
            value: option[name],
            label: <div className='aacOptionLabel'>{option[name]} {(showDist ? <span className="district"> {distStr}</span> : '')}</div>,
            data: option,
            field: name,

        }
    });


    return (
        <AutoComplete
        options={options}
            onSearch={handleSearch}
            onSelect={handleSelect}
            defaultOpen={true}
            open={inFocus && options?.length > 0 && (lastSelected && missingNumber)?true:undefined}
            onDropdownVisibleChange={handleVisibleChange}

            onFocus={() => { focusRef.current = true; setInFocus(true); }}
            onBlur={() => { focusRef.current = false; setInFocus(false);}}
            {...{...props, placeholder: undefined}}
            ref={ref}
        >
            <Input {...props} ref={refInput} />
        </AutoComplete>
    );
}