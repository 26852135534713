import React, { useState } from 'react';
import { App, Button, ConfigProvider, theme } from 'antd';
import './Main.css';
import { StateProvider } from './store/StateProvider';
import { SessionExpirationProvider } from "./store/SessionExpirationContext";
import PageRouter from './components/PageRouter';
// import FormWrapper from './components/FormWrapper';
import { AACStateProvider } from './components/helpers/AACInput';

const { darkAlgorithm, compactAlgorithm } = theme;

function Main() {

    return (
        <ConfigProvider
            theme={{
                token: {
                    // colorPrimary: '#00b96b', // Customize the primary color
                    colorPrimary: '#bccf21', // Customize the primary color
                    // colorBgContainer: '#f0f2f5', // Customize the background color
                    // colorText: '#333',
                    // colorTextBase: '#000', // Customize the text color
                    // colorTextPlaceholder: '#000', // Customize the placeholder color
                    // colorBgLayout: '#444',
                    colorBgBase: '#222', // Customize the background color
                    // controlHeightSM: 60, // Customize the height of the control
                    // fontSizeSM: 20, // Customize the font size
                    // controlHeightLG: 60, // Customize the height of the control
                    // fontSizeLG: 20, // Customize the font size
                },
                // mode: 'dark', // Enable dark mode by setting the mode to 'dark'

                algorithm: [darkAlgorithm], // Customize the color scheme
            }}
        >
            <App>
                <StateProvider>
                    <AACStateProvider>
                        <SessionExpirationProvider>
                            {/* <FormWrapper> */}
                            <PageRouter />
                            {/* </FormWrapper> */}
                        </SessionExpirationProvider>
                    </AACStateProvider>
                </StateProvider>
            </App>
        </ConfigProvider>
    );
}

export default Main;
