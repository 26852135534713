import { useState, useRef } from "react";
import axios from 'axios';
// import { Form } from 'antd';

// import { useNavigate, useLocation } from 'react-router-dom';

const defaultPayments = [
    { id: 1, name: "Zálohová faktura" },
    { id: 2, name: "Online převod / kartou" },
];

const defaultPromotions = [
    // {
    //     id: 1,
    //     name: "AKCE Léto 2024",
    //     price: 999,
    //     // description: "Červenec a srpen 2024 za <b>super zenu!</b>",
    //     banner: "\"AKCE LÉTO 2024\" - měsíc červenec a srpen za 999 Kč",
    //     period: 2,
    //     monthStart: 6,
    //     isDefault: true,
    //     asPeriod: true
    // },
    // {
    //     id: 2,
    //     name: "AKCE Zima 2024",
    //     price: 999,
    //     // description: "Červenec a srpen 2024 za <b>super zenu!</b>",
    //     banner: "\"AKCE Zima 2024\" - měsíc prosinec a leden za 999 Kč",
    //     period: 2,
    //     monthStart: 6,
    //     isDefault: true,
    //     asPeriod: true
    // },
];



export const useGlobalState = () => {
    //const pages = ["home"];
    const [page, setPage] = useState("home");
    const domain = window.location.hostname;
    const baseURL = window._env_?.BASE_URL ?? 'http://' + domain + '/j3a';
    const apiBase = baseURL.replace(/\/$/, '') + '/index.php?option=com_nakoleipesky&task=api&format=json&path=';
    const formValues = useRef({});
    const backUrl = useRef({});
    const paymentMethods = window._env_?.PAYMENTS ?? defaultPayments;

    const promotions = window._env_?.PROMOTIONS ?? defaultPromotions; //array of objects {id, name, price}

    // const form = Form.useForm();
    const [fileList, setFileList] = useState([]);

    const axi = axios.create({
        withCredentials: true,
        baseURL: apiBase,
    })

    const storeFormValues = (formName, values) => {
        console.log("storeFormValues/" + formName + ": ", values);
        formValues.current = { ...formValues.current, [formName]: values };


        sessionStorage.setItem("formValues", JSON.stringify(formValues.current));


        // console.log("currFormValues: ", formValues.current);

    }

    const getFormValues = (formName) => {
        // console.log("getFormValues/"+formName);

        const stored = sessionStorage.getItem("formValues");

        // console.log("stored: ", stored);
        // console.log("current: ", formValues.current);



        if (stored && (!formValues.current || (formValues.current.constructor === Object && Object.keys(formValues.current).length === 0))) {
            console.log("loading stored values: ", JSON.parse(stored));
            formValues.current = JSON.parse(stored);
        }


        const vals = formValues.current[formName] ?? {};
        // console.log("getFormValues/"+formName+": ", vals);
        return formValues.current[formName];
    }

    const fieldsAreFilled = () => {
        //check if all necessary data in "business" form is filled
        const formValues = getFormValues("business");
        if (!formValues) return false;
        const requiredFields = ["businessName", "street", "city", "description"];
        const isFilled = requiredFields.every((field) => (formValues[field] !== undefined && formValues[field] !== null && formValues[field] !== ""));
        return isFilled;
    }

    const hasImages = () => {
        return fileList?.length > 0;
    }

    const isAllFilled = () => {
        return fieldsAreFilled() && hasImages();
    }


    const restartApp = (form) => {
        if (fileList.length > 0) {
            setFileList([]);
        }
        if (form !== null) {
            form.resetFields(); //antd form
        }
    }

    const clear = () => {
        formValues.current = {};
        clearSessionDependentData();
    }


    const clearSessionDependentData = () => {
        setFileList([]);
    }

    // const popMessage = ({type, message}) => {
    //     setMessage({type, message});
    // }

    // const popError = (message) => {
    //     popMessage({type:"error", message});
    // }

    // const quit = () => {
    //     setOpened(false);
    //     // navigate("/");
    // }

    return {
        page,
        setPage,
        axios: axi,
        baseURL,
        apiBase,
        getBaseURL: () => baseURL,
        fileList,
        storeFormValues,
        getFormValues,
        storeBackUrl: (currurl) => backUrl.current = currurl,
        getBackUrl: (defaultUrl) => typeof backUrl.current === "string" ? backUrl.current : defaultUrl,
        setFileList,
        restartApp,
        clear,
        clearSessionDependentData,
        isAllFilled,
        hasImages,
        fieldsAreFilled,
        paymentMethods,
        promotions,
    }

}