import React, { createContext, useContext, useState, useEffect } from "react";
import { Modal, message, antd } from "antd";
import { useStore } from "./useStore";

const SessionExpirationContext = createContext();

const extendSession = async (apiBase) => {
    // Handle extending the session here

    try {
        const response = await fetch(`${apiBase}/extend-session`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
        });
        const data = await response.json();
        console.log("Success:", data);
        const exp = data?.sessionExpirationRemainingTime ?? null;
        if (!exp) {
            throw new Error("Failed to extend session, invalid response");
        }
        return exp;
    } catch (error) {
        console.error("Error:", error);

    }

    return false;
};

export function useSessionExpiration() {
    return useContext(SessionExpirationContext);
}

export function SessionExpirationProvider({ children }) {
    const [sessionExpireTime, setSessionExpireTime] = useState(null); // unix timestamp
    const [confirmOpen, setConfirmOpen] = useState(false);
    const timerRef = React.useRef(null);

    const store = useStore();
    const { baseURL, apiBase, page } = store;

    const noticeBeforeExpiration = 30 // seconds

    useEffect(() => {
        if (sessionExpireTime) {
            if (sessionExpireTime < Date.now() / 1000) {
                timerRef.current = setTimeout(handleSessionExpiration, 1000);
            }

            const remainingTime = sessionExpireTime - Date.now() / 1000;
            if (remainingTime > noticeBeforeExpiration) {
                // alert("Session expiration time: " + sessionExpireTime + ", remaining time: " + remainingTime);
                timerRef.current = setTimeout(handleSessionExtension, (remainingTime - noticeBeforeExpiration) * 1000);
            } else {
                // handleSessionExpiration();
                return () => { };
            }
        } else {
            //no session, no problem :)
            return () => { };
        }
    }, [sessionExpireTime]);

    function handleSessionExpiration() {
        // console.log("Session expired");
        // Handle session expiration here
        setSessionExpireTime(null);
        store.clearSessionDependentData();
    }

    function handleSessionExtension() {
        // console.log("Extending Session");
        extendSession(apiBase).then((remainingSeconds) => {
            // console.log("remainingSeconds", remainingSeconds);
            setSessionExpireTime(Date.now() / 1000 + remainingSeconds);
        }).catch((error) => {
            console.error("Error extending session:", error);
            handleSessionExpiration();
        });
    }

    const value = {
        setSessionExpireTime,
        setSessionRemainingTime: (remainingSeconds) => {
            setSessionExpireTime(Date.now() / 1000 + remainingSeconds);
        },
        sessionExpireTime
    };

    return (
        <SessionExpirationContext.Provider value={value}>
            {children}
        </SessionExpirationContext.Provider>
    );
}