import React, { useState } from "react";

const ReadMore = ({ children, moreText=">>>více", lessText="<<<méně", size=150, maxSize=250 }) => {
    const text = children.length > maxSize ? children.slice(0, maxSize) : children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? text.slice(0, size)+"..." : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? moreText : lessText}
        </span>
      </p>
    );
  };

  export default ReadMore;