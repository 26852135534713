import React, { useRef, useEffect } from 'react';
import { Input, Form } from 'antd';
import { FacebookOutlined, InstagramOutlined, UserOutlined } from '@ant-design/icons';
import { useStore } from '../../../store/useStore';
import ExpandableInput from '../../helpers/ExpandableInput';
import { MultiInput, FakeInput } from '../../helpers/FakeInput';
import PictoSelect from '../../helpers/PictoSelect';


const addressReducer = (fields) => {
    const isEmpty = Object.values(fields).every((x) => x === null || x === "");

    if (isEmpty) {
        return null;
    }
    return (
        <>
            {fields.street && <strong>{fields.street}</strong>}
            {fields.street && fields.city && ", "}
            {fields.city && <em>{fields.city}</em>}
        </>
    );
};

const socialMediaReducer = (fields) => {
    return (
        <>
            <FacebookOutlined />
            {fields.facebook && <span>{fields.facebook}</span>} |
            <InstagramOutlined />
            {fields.instagrem && <span>{fields.instagrem}</span>}
            {fields.other && <> | <UserOutlined /> <span>{fields.other}</span></>}
        </>
    );
};






const AddressData = () => {
    // const { form } = useStore();
    const firstInputRef = useRef(null);
    const firstInputRef2 = useRef(null);
    const form = Form.useFormInstance();

    const layout = {
        labelCol: { span: 0 },
    };


    return (
        <div className="addressData">
            <Form.Item
                name="businessName"
                label="Název provozovny"
                rules={[{ required: true, message: 'Zadejte název provozovny' }]}
            >
                <Input prefix={"Název *"} placeholder="Nejlepší provozovna" />
            </Form.Item>

            {/* <ExpandableInput
                name="address"
                label="Adresa"
                placeholder="Ulice a č.p., Obec, PSČ"
                reducer={addressReducer}
                firstInputRef={firstInputRef}
                prefix={"Adresa *"}
                rules={[
                    {validator: (rule, value) => {
                        console.log("Validating: ", value);
                        return value.street && value.city && value.zip ? Promise.resolve() : Promise.reject('Vyplňte celou adresu');
                    }}
                ]}
                
            >
                
                <Form.Item name="street" label="Ulice a č.p." 
                    rules={[{ required: true, message: 'Zadejte ulici a č.p.' }]}
                >
                    <Input prefix="Ulice *" ref={firstInputRef} />
                </Form.Item>
                <Form.Item name="city" label="Obec"
                    rules={[{ required: true, message: 'Zadejte obec' }]}
                >
                    <Input prefix="Obec *" />
                </Form.Item>
                <Form.Item name="zip" label="PSČ"
                    rules={[{ required: true, message: 'Zadejte PSČ' }]}
                >
    
                    <Input prefix="PSČ *" />
                </Form.Item>
                <Form.Item name="other" label="Upřesnění">
                    <Input prefix="Upřesnění" />
                </Form.Item>
            </ExpandableInput> */}

            <Form.Item name="address" label="Adresa"
                rules={[
                    {validator: (_, value) => {
                        // console.log("Validating: ", _, value);
                        const vals = form.getFieldsValue();
                        // console.log("Addr: ", addr);
                        return vals.street && vals.city && vals.zip ? Promise.resolve() : Promise.reject('Vyplňte celou adresu');
                    }}
                ]}
            >
            <MultiInput
                name="address"
                // label="Adresa"
                placeholder="Ulice a č.p., Obec, PSČ"
                reducer={addressReducer}
                firstInputRef={firstInputRef}
                // prefix={"Adresa *"}
                rules={[
                    {
                        validator: (rule, value) => {
                            // console.log("Validating: ", value);
                            return value.street && value.city && value.zip ? Promise.resolve() : Promise.reject('Vyplňte celou adresu');
                        }
                    }
                ]}
            >
                <Form.Item name="street" label="Ulice a č.p."
                    // rules={[{ required: true, message: 'Zadejte ulici a č.p.' }]}
                >
                    <Input prefix="Ulice *" ref={firstInputRef} 
                        placeholder='Ulice a č.p.'
                        bordered={false}
                    />
                </Form.Item>
                <Form.Item name="city" label="Obec"
                    // rules={[{ required: true, message: 'Zadejte obec' }]}
                >
                    <Input prefix="Obec *"
                        placeholder='Obec'
                        bordered={false}
                    />
                </Form.Item>
                <Form.Item name="zip" label="PSČ"
                    // rules={[{ required: true, message: 'Zadejte PSČ' }]}
                    
                >

                    <Input prefix="PSČ *"
                        placeholder='123 45'
                        bordered={false}
                    />
                </Form.Item>
                {/* <Form.Item name="other" label="Upřesnění">
                    <Input prefix="Upřesnění" 
                        bordered={false}
                        />
                </Form.Item> */}
            </MultiInput>
            </Form.Item>


            <Form.Item name="kat" label="Kategorie"
                rules={[{validator: (_, value) => {
                    // return value.kategorie ? Promise.resolve() : Promise.reject('Vyberte druh provozovny');
                    return form.getFieldValue('kategorie')?.length??false ? Promise.resolve() : Promise.reject('Vyberte druh provozovny');
                }}]}
            >
                <MultiInput className="category" name="cat" label="Kategorie" placeholder="Kategorie" prefix="Co jsme *"
                    rules={[{ required: true, message: 'Vyberte druh provozovny' }]}
                >
                    <Form.Item
                        name="kategorie"
                        label="Činnost *"
                    >
                        <PictoSelect
                            prefix="Vaše služby"
                            bordered={false}
                            placeholder="Vyberte druh provozovny"
                        />
                    </Form.Item>
                </MultiInput>
            </Form.Item>

            <Form.Item
                name="web"
                label="Web"
            >
                <Input prefix="Web" placeholder="mojeprovozovna.cz" />
            </Form.Item>
            <Form.Item
                name="email"
                label="Email"
            >
                <Input prefix="Email" placeholder="muj@email.xy" />
            </Form.Item>

            <Form.Item name="social" label="Facebook">
                <MultiInput name="social" label="Sociální sítě" placeholder="Socky" >
                    <Form.Item name={"facebook"} label="Facebook">
                        <Input
                            prefix={<>Socky<span> </span><FacebookOutlined className="site-form-item-icon" /></>}
                            placeholder="Facebook"
                            ref={firstInputRef2}
                            bordered={false}
                        />
                    </Form.Item>
                    <Form.Item name={"instagram"} label="Instagram">
                        <Input
                            prefix={<InstagramOutlined className="site-form-item-icon" />}
                            placeholder="Instagram"
                            bordered={false}
                        />
                    </Form.Item>
                    {/* <Form.Item name={["social","other"]} label="Další">
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Další "
                    />
                </Form.Item> */}
                </MultiInput>
            </Form.Item>

            <Form.Item
                name="more"
                label="Další"
            >
                <Input prefix="Další" placeholder="Co se nevejde jinam" />
            </Form.Item>


            {/* <ExpandableInput
                name="social"
                placeholder="Socky"
                label="Sociální sítě"
                reducer={socialMediaReducer}
                firstInputRef={firstInputRef2}
                prefix="Socky"
            >
                <Form.Item name="facebook" label="Facebook">
                    <Input 
                        prefix={<FacebookOutlined className="site-form-item-icon" />}
                        placeholder="Facebook" 
                        ref={firstInputRef2}
                    />
                </Form.Item>
                <Form.Item name="instagram" label="Instagram">
                    <Input 
                        prefix={<InstagramOutlined className="site-form-item-icon" />}
                        placeholder="Instagram" 
                    />
                </Form.Item>
                <Form.Item name="other" label="Další">
                    <Input 
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Další sociální síť/sítě"
                    />
                </Form.Item>
            </ExpandableInput> */}
        </div>
    );
}

export default AddressData;