import { Space } from 'antd';

export const FakeInput = ({value, className, placeholder, label, prefix, ...rest }) => {

    return (
        <div
            {...rest}
            className={className+" ant-input-alt ant-input css-dev-only-do-not-override-1nye79x"}
        >
            {prefix && <span className="ant-input-prefix">{prefix}</span>}
            {value || (
                <span className="placeholder">{placeholder}</span>
            )}
        </div>
    );
};



export const MultiInput = ({className="", children, ...props}) => {
    return (
        <FakeInput className={className+" multiInput"} {...props} value={<Space.Compact>{children}</Space.Compact>} />
    );
};