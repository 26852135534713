import { useStore } from "../../../store/useStore"
// Description: PhoneDisplay component - displays children in a phone display layout. Vertical orientation.
const PhoneDevice = ({ children, deviceHeight=0 }) => {
    const sizeUnit = deviceHeight/100;
    const shadowBlur = Math.round(sizeUnit*2.5);//how to round to int? Math.round(sizeUnit*1.5); ? and how to get only decimal part? sizeUnit*1.5 - Math.round(sizeUnit*1.5); ?
    const shadowSpread = Math.round(sizeUnit*1);
    const borderWidth = Math.round(sizeUnit*0.8);
    const wrapperStyle = {
        // width: Math.round(deviceHeight*396/816)+"px",
        height: deviceHeight+"px",
        padding: "0 2rem 2rem 2rem",
        boxSizing: 'border-box',
        minHeight: "200px",
    }

    const style = {
        boxShadow: `0 0 ${shadowBlur}px ${shadowSpread}px rgba(0, 0, 0, 0.4)`,
        border: `${borderWidth}px solid #2f2f2f`,
    }

    const store = useStore();

    const allIsFilled = store.isAllFilled();



    return (
        <div className="devWrapper" style={wrapperStyle}>
            <div className="device" style={style}>
            <section>
                {children}
            </section>
            {(!allIsFilled) && (((!store.fieldsAreFilled() && <div className="notFilled">Některé údaje nejsou vyplněny</div>)) || <div className="notFilled">Nejsou nahrány žádné obrázky</div>)}
            
            </div>
        </div>
    );
}

export default PhoneDevice;