import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useStore } from '../../store/useStore';
import { Form, Input, Radio, Select, Checkbox, Switch, message, Modal, AutoComplete } from 'antd';
import CustomDiscreteSlider from './Checkout/CustomDiscreteSlider';
import axios from 'axios';
import AppButton from '../helpers/AppButton';

import { useNavigate } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { PictogramIcon } from '../helpers/Pictograms';
import { AACInput } from '../helpers/AACInput';

const { Option } = Select;

const maxDayOfMonthToAllowNextMonth = 15;

const priceForPeriod = {
    3: 2000,
    6: 3300,
    9: 4600,
    12: 6000,
    24: 10000
}
const premiumPricePerMonth = 100;
const premiumPricePercent = 20;
const premiumPriceFixed = null;

let nextMonth = (new Date().getMonth() + 1) % 12;
//if this day is in it's second half, add one month
if (new Date().getDate() > maxDayOfMonthToAllowNextMonth) {
    nextMonth = (nextMonth + 1) % 12;
}

const monthNames = ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'];

let months = [];
const currMonth = new Date().getMonth();
console.log("currMonth: ", currMonth);
let baseYear = new Date().getFullYear();
if (nextMonth < currMonth) {
    baseYear++;
}
for (let i = 0; i < 12; i++) {
    let realI = (currMonth + i) % 12;
    let year = baseYear + (realI < currMonth ? 1 : 0);
    let mName = monthNames[realI] + " " + year;
    //add year if it's next year
    months.push({ label: mName, value: realI });
}

const getDaysCount = (monthStart, months) => {
    const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let days = 0;
    for (let i = 0; i < months; i++) {
        days += monthDays[(monthStart + i) % 12];
    }

    return days;
}


const numberToPrice = (number, nbDecimals = 0) => {
    //if number is not a number, return it as is
    if (isNaN(number)) {
        return number;
    }
    //fixed number of decimals (1)
    let price = number.toFixed(nbDecimals).replace(".", ",");
    if (price.indexOf(",") !== -1) {
        //add zeros to fill up to 2 decimals
        price += "0".repeat(2 - price.split(",")[1].length);
    } else {
    }
    price += " Kč";
    return price;
}

const calculatePrices = (rawvalues, promotions) => {
    let values = { ...rawvalues };
    let preTotal = priceForPeriod[values.period];

    // console.log("Period: ", values.period, preTotal)
    let total = preTotal;

    if (values.promo) {
        const promo = promotions?.find((item) => item.id === values.promo) ?? null;
        if ((promo) && (promo.price) && (promo.price > 0)) {
            values.period = promo.period;
            values.monthStart = promo.monthStart;
            preTotal = promo.price;
            //make sure it's a number
            total = 0 + promo.price;
        }
    }

    if ((values.period + "").startsWith('promo')) {
        // console.log("computePrices promo: ", values.period, promotions);
        const promoId = +(values.period.split(':')[1]);
        // console.log("promoId: ", promoId);
        const promo = promotions.find((item) => item.id == promoId);
        // console.log("promo: ", promo);
        if (promo) {
            if ((promo.price) && (promo.price > 0)) {
                preTotal = promo.price;
                total = 0 + promo.price;
            }
            if (typeof promo['period'] !== 'undefined') {
                values.period = promo.period;
            }
            if (typeof promo['monthStart'] !== 'undefined') {
                values.monthStart = promo.monthStart;
            }
            // console.log("New values: ", values);
        } else {
            // console.error("Promo not found: ", promoId);
        }
    }


    if (values.premium) {
        if (premiumPriceFixed) {
            total = preTotal + premiumPriceFixed;
        } else if (premiumPricePercent) {
            total = preTotal * (+premiumPricePercent + 100) / 100;
        } else {
            total = preTotal + values.period * (values.premium ? premiumPricePerMonth : 0);
        }
    }
    // console.log("total: ", total);
    let perMonth = total / values.period;
    perMonth = Math.ceil(perMonth * 10) / 10
    // console.log("days: ", getDaysCount(values.monthStart, values.period));
    let perDay = total / getDaysCount(values.monthStart, values.period);
    perDay = Math.ceil(perDay * 10) / 10

    return { total, perMonth, perDay };
}


const Checkout = () => {

    const { paymentMethods, baseURL, promotions } = useStore();

    const periodPromotions = promotions.filter((item) => item.asPeriod ?? false);
    const fullPromotions = promotions.filter((item) => !(item.asPeriod ?? false));

    const { storeFormValues, getFormValues, getBackUrl, apiBase } = useStore();

    const [premiumPreviewOpen, setPremiumPreviewOpen] = useState(false);

    const businessFormValues = getFormValues('business') ?? null;
    // console.log("businessFormValues: ", businessFormValues);

    const navigate = useNavigate();

    const autocompleteUrl = `${baseURL}/plugins/system/aacwhisperer/ajax.php`;
    const aacUrlBase = autocompleteUrl + (autocompleteUrl.indexOf('?') === -1 ? '?' : '&') + 'format=json&field=';

    const defaultPromo = fullPromotions.find((item) => item.isDefault ?? false)?.id ?? 0;

    const defaultPeriodPromo = periodPromotions.find((item) => item.isDefault ?? false) ?? null;
    const defaultPeriods = [3, 6, 9, 12];
    //mix of periods and promotions, sorted by period (or number if not object)
    const periodsAndPromos = defaultPeriods.concat(periodPromotions).sort((a, b) => {
        let aVal = a;
        let bVal = b;
        if (typeof a === 'object') {
            aVal = a.period;
        }
        if (typeof b === 'object') {
            bVal = b.period;
        }
        return aVal - bVal;
    });
    // console.log("periodsAndPromos: ", periodsAndPromos);

    //const defaultPeriodPromoStart = defaultPeriodPromo?.monthStart ?? nextMonth;

    const initialValues = {
        period: defaultPeriodPromo !== null ? "promo:" + defaultPeriodPromo.id : 9,
        premium: false,
        paymentMethod: paymentMethods[0].id,
        monthStart: defaultPeriodPromo?.monthStart ?? nextMonth,
        email: businessFormValues?.email ?? null,
        promo: defaultPromo,
    }

    // console.log("initialValues: ", initialValues);





    const [prices, setPrices] = useState(calculatePrices(initialValues, promotions));
    // const [total, setTotal] = useState(0);
    // const [pricePerMonth, setPricePerMonth] = useState(0);
    // const [pricePerDay, setPricePerDay] = useState(0);

    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 0, offset: 0 },
    };


    useEffect(() => {
        let storedValues = getFormValues('checkout');
        let storedBusinessValues = getFormValues('business');
        if (storedValues) {
            //remove empty values
            for (const [key, value] of Object.entries(storedValues)) {
                if (value === null || value === "") {
                    delete storedValues[key];
                }
            }
            // console.log("storingValues: ", storedValues);

            form.setFieldsValue(storedValues);
        }

        if (!storedBusinessValues) {
            console.log("no business values");
            navigate(getBackUrl('/form'));
        }
    }, [form]);


    const premiumPreviewLink = baseURL + "/images/premiumListPreview.png";

    const handleChange = (e) => {
        //     const priceTotal = form.getFieldValue("period")*1000;
        //     console.log("period: ", form.getFieldsValue(), priceTotal);
        //     setTotal(priceTotal);
    }

    const getBusinessFormCleanValues = () => {
        const raw = getFormValues("business");
        //extract only ids from images
        const images = raw.images?.fileList?.map((item) => item.response?.id ?? null).filter((item) => item !== null);

        return {
            ...raw,
            images,
        }

    }

    const handlePremiumPreview = (e) => {
        setPremiumPreviewOpen(true);
        e.preventDefault();
        return false;
    }

    const handleBack = () => {
        //get current values
        const values = form.getFieldsValue();
        storeFormValues('checkout', values);
        console.log("back url: ", getBackUrl('/form'));
        navigate(getBackUrl('/form'));
    }

    const handleCheckout = () => {
        const values = form.getFieldsValue();
        storeFormValues('checkout', values);
        form.validateFields().then((values) => {
            // console.log("values: ", values);



            const data = {
                "billTo": values,
                "total": prices.total,
                "business": getBusinessFormCleanValues(),
                "promotions": promotions,
            }

            // console.log("data: ", data);


            axios.defaults.withCredentials = true

            axios.post(`${apiBase}/checkout`, data).then((res) => {
                console.log("res: ", res);
                if (res.status === 200) {
                    // window.location.href = res.data.redirect;
                    if (res.data.redirect) {
                        window.location.href = res.data.redirect;
                    }
                    if (res.data.status === false) {
                        message.error(res.data.message);
                    }
                    if ((res.data.status === true) && (!res.data.redirect)) {
                        navigate('/thankyou');
                    }

                }
            }).catch((err) => {
                console.log("err: ", err);
                message.error("Něco se pokazilo: \n" + err.message);
            })



            //submit to backend
            //redirect to payment gateway
            //redirect to success page

        }).catch((info) => {
            console.log('Validate Failed:', info);
            message.error('Některá pole nejsou vyplněna');
        });
    }


    const handlePriceChange = (field, value) => {
        // console.log("handlePriceChange: ", field, value);
        const oldValues = form.getFieldsValue();
        let values = { ...oldValues}
        if (field) {
            values = { ...values, [field]: value };
        } 
        
        if (field === 'period') {
            const tmpVal = value.split?.(':') ?? [];
            const promoId = tmpVal[0] === 'promo' ? +(tmpVal[1]) : 0;
            const promo = promoId ? (promotions.find((item) => item.id === promoId) ?? null) : null;
            const promoDefinesStart = promo && typeof promo['monthStart'] !== 'undefined';

            if (promoDefinesStart) {
                form.setFieldsValue({ monthStart: promo.monthStart });
            }

        }
        if (!values.period) {
            values.period = initialValues.period;
            if (!values.monthStart) {
                values.monthStart = initialValues.monthStart;
            }
            form.setFieldsValue({ period: initialValues.period, monthStart: values.monthStart });
        }

        // console.log("values: ", values);
        setPrices(calculatePrices(values, promotions));
    }




    return (
        <div className="pageCheckout fullpage" >
            <div className="pageContent">
                <Form form={form} name="billTo" {...layout} layout="horizontal" onChange={handleChange} initialValues={initialValues}>
                    <div className="billTo noLabel">
                        <h1><PictogramIcon value="pokracovat" type="icon" /><b>Vyplň</b> fakturační údaje</h1>
                        <Form.Item
                            className="noLabel"
                            name="company"
                            label="Firma"
                        // rules={[{ required: true, message: 'Zadejte název firmy nebo jméno' }]}
                        >
                            <Input prefix={"Firma"} placeholder="Firma" name="company" />
                        </Form.Item>
                        <Form.Item
                            className="noLabel"
                            name="street"
                            label="Ulice *"
                            rules={[{ required: true, message: 'Zadejte ulici' }]}
                        >
                            <AACInput prefix={"Ulice *"} placeholder="Ulice" name="street" form={form} url={aacUrlBase + 'street'} />
                        </Form.Item>
                        <Form.Item
                            className="noLabel"
                            name="city"
                            label="Město *"
                            rules={[{ required: true, message: 'Zadejte město' }]}
                        >
                            <AACInput prefix={"Město *"} placeholder="Město" name="city" form={form} url={aacUrlBase + 'city'} />
                        </Form.Item>
                        <Form.Item
                            className="noLabel"
                            name="zip"
                            label="PSČ *"
                            rules={[{ required: true, message: 'Zadejte PSČ' }]}
                        >
                            <AACInput prefix={"PSČ *"} placeholder="PSČ" name="zip" form={form} url={aacUrlBase + 'zip'} />
                        </Form.Item>
                        <Form.Item
                            className="noLabel"
                            name="email"
                            label="Email *"
                            rules={[{ required: true, message: 'Zadejte email' }]}
                        >
                            <Input prefix={"Email *"} placeholder="Email" name="email" />
                        </Form.Item>
                        <Form.Item
                            className="noLabel"
                            name="phone"
                            label="Telefon *"
                            rules={[{ required: true, message: 'Zadejte telefon' }]}

                        >
                            <Input prefix={"Telefon *"} placeholder="Telefon" name="phone" />
                        </Form.Item>
                        <Form.Item
                            className="noLabel"
                            name="name"
                            label="Kontaktní osoba"
                            rules={[{ required: true, message: 'Zadejte kontaktní osobu' }]}
                        >
                            <Input prefix={"Kontaktní osoba *"} placeholder="Jan Novák" name="name" />
                        </Form.Item>
                        <Form.Item
                            name="ic"
                            label="IČ"
                            className="noLabel"
                        >
                            <Input prefix={"IČ"} placeholder="12345678" name="ic" />
                        </Form.Item>
                        <Form.Item
                            name="dic"
                            label="DIČ"
                            className="noLabel"
                        >
                            <Input prefix={"DIČ"} placeholder="CZ12345678" name="dic" />
                        </Form.Item>
                    </div>
                    <div className="billingOptions">
                        <h1><PictogramIcon value="pokracovat" type="icon" /><b>Zvol</b> parametry</h1>

                        {promotions.length > 0 && (<>
                            {promotions.map((promoItem) => {
                                if (!promoItem.banner) return null;
                                return (
                                    <p className="promoBanner" key={promoItem.id} dangerouslySetInnerHTML={{ __html: promoItem.banner }} />
                                )
                            })}

                        </>)}

                        {fullPromotions.length > 0 && (
                            <Form.Item
                                labelCol={{ offset: 0, span: 6 }}
                                name="promo"
                                label="Akce"
                                className='promoSwitch'
                            >
                                <Radio.Group onChange={(v) => handlePriceChange('promo', v.target.value)} >
                                    <Radio.Button value={0}>Běžný ceník</Radio.Button>
                                    {fullPromotions.map((promoItem) => (
                                        <Radio.Button value={promoItem.id} key={promoItem.id}>{promoItem.name}</Radio.Button>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        )}


                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.promo !== currentValues.promo}
                        >
                            {({ getFieldValue }) => {
                                const promo = getFieldValue('promo') ?? 0;
                                return (
                                    <>
                                        {(promo==0) && (
                                            <Form.Item
                                                name="period"
                                                label="Počet měsíců"
                                                labelCol={{ offset: 0, span: 6 }}
                                            >
                                                {/* <CustomDiscreteSlider marks={['3', '6', '9', '12', '24']} onChange={(v) => handlePriceChange('period', v)} /> */}

                                                <Radio.Group onChange={(v) => handlePriceChange('period', v.target.value)} >
                                                    {periodsAndPromos.map((item) => {
                                                        const value = typeof item === 'object' ? "promo:" + item.id : item;
                                                        const label = typeof item === 'object' ? item.name : item;
                                                        return (<Radio.Button value={value} key={value}>{label}</Radio.Button>)
                                                    }
                                                    )}
                                                </Radio.Group>

                                            </Form.Item>
                                        )}
                                    </>)
                            }
                            }
                        </Form.Item>


                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.promo !== currentValues.promo}
                        >
                            {({ getFieldValue }) => {
                                const v = getFieldValue('period');
                                const fullPromoVal = getFieldValue('promo')??0;
                                const fullPromo = fullPromoVal ? promotions.find((item) => item.id == fullPromoVal) ?? false : null;
                                const tmpVal = (v + "").split(':');
                                const promoId = tmpVal[0] == 'promo' ? +(tmpVal[1]) : 0;
                                const promo = promoId ? promotions.find((item) => item.id == promoId) ?? false : null;
                                const promoDefinesStart = promo && (typeof promo['monthStart'] !== 'undefined');
                                const fullPromoDefinesStart = fullPromo && (typeof fullPromo['monthStart'] !== 'undefined');
                                // console.log("fullPromoDefinesStart: ", fullPromoDefinesStart);

                                return (
                                    <>
                                        {promo && promo.description && (
                                            <Form.Item
                                                labelCol={{ offset: 0, span: 6 }}
                                                name="promoInfo"
                                                label=" &nbsp; "
                                                colon={false}
                                            >
                                                <p dangerouslySetInnerHTML={{ __html: promo.description ?? '' }} />
                                            </Form.Item>

                                        )}
                                        {((fullPromoVal==0) || (!fullPromoDefinesStart)) && (
                                        <Form.Item
                                            name="monthStart"
                                            label="Začátek"
                                            labelCol={{ offset: 0, span: 6 }}
                                        >
                                            <Select
                                                disabled={promoDefinesStart}
                                                options={months}
                                                onChange={(v) => handlePriceChange('monthStart', v)}
                                            />
                                        </Form.Item>)}

                                        {/* 
                                            <Form.Item
                                                name="period"
                                                label="Počet měsíců"
                                                labelCol={{ offset: 0, span: 6 }}
                                            >
                                                <CustomDiscreteSlider marks={['3', '6', '9', '12', '24']} onChange={(v) => handlePriceChange('period', v)} />
                                            </Form.Item> */}

                                    </>
                                )
                            }
                            }
                        </Form.Item>

                        <Form.Item
                            labelCol={{ offset: 0, span: 6 }}
                            name="premium"
                            label="Prémiové zvýraznění"
                            className='premiumSwitch'
                            // label={<div>Prémiové zvýraznění <span className='premiumPreviewLink' onClick={handlePremiumPreview}>(<span>Zobrazit ukázku</span>)</span></div>}
                            extra={<span className='premiumPreviewLink' onClick={handlePremiumPreview}>(<span>Zobrazit ukázku</span>)</span>}
                        // valuePropName='checked'


                        // wrapperCol={{ span: 3 }}
                        // labelCol={{ offset: 0, span: 3 }}
                        // style={{marginBottom: 0, textAlign: "left"}}

                        >
                            {/* <Switch checkedChildren="Ano" unCheckedChildren="Ne" onChange={(v)=>handlePriceChange('premium', v)} /> */}
                            <Radio.Group >
                                <Radio.Button value={true} onClick={(v) => handlePriceChange('premium', true)}>Ano</Radio.Button>
                                <Radio.Button value={false} onClick={(v) => handlePriceChange('premium', false)}>Ne</Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            labelCol={{ offset: 0, span: 6 }}
                            name="paymentMethod"
                            label="Způsob platby"
                            rules={[{ required: true, message: 'Vyberte způsob platby' }]}
                        >
                            <Radio.Group >
                                {paymentMethods.map((item) => {
                                    const selected = item.selected ? true : false
                                    return <Radio.Button value={item.id} key={item.id} >{item.name}</Radio.Button>
                                })}
                            </Radio.Group>
                        </Form.Item>


                        <div className="priceCalculation">
                            <div className="priceCalculation__item perday">
                                <span className="priceCalculation__item__value">{numberToPrice(prices.perDay, 1)}</span>
                                <span className="priceCalculation__item__label">1 den</span>
                            </div>
                            <div className="priceCalculation__item total ">
                                <span className="priceCalculation__item__value">{numberToPrice(prices.total)}</span>
                                <span className="priceCalculation__item__label">celkem</span>
                            </div>
                        </div>

                        <div className="priceInfo">
                            <p>Ceny uvedeny bez 21% DPH</p>
                        </div>

                        <div className='agreement'>
                            <Form.Item
                                name="agreement"
                                valuePropName="checked"
                                rules={[{ required: true, message: 'Musíte souhlasit s podmínkami' }]}
                                labelCol={{ offset: 6, span: 0 }}
                                wrapperCol={{ offset: 6, span: 18 }}
                            >
                                <Checkbox>Souhlasím s <a href="https://konfigurator.nakoleipesky.cz/images/obchodni-podminky.pdf" target="_blank" >obchodními podmínkami</a></Checkbox>
                            </Form.Item>
                        </div>

                    </div>

                </Form>
                <div className='buttons'>
                    <AppButton onClick={handleBack}><LeftOutlined /> Zpět</AppButton>
                    <AppButton type="primary" onClick={handleCheckout}>Odeslat objednávku</AppButton>
                </div>
                <div className='buttonsDesktop'>
                    <AppButton onClick={handleBack} size="large"><LeftOutlined size='large' /> Zpět</AppButton>
                    <AppButton type="primary" size='large' onClick={handleCheckout}>Odeslat objednávku</AppButton>
                </div>
            </div>

            <Modal
                title="Prémiové zvýraznění"
                open={premiumPreviewOpen}
                onClose={() => setPremiumPreviewOpen(false)}
                // onOk={() => setPremiumPreviewOpen(false)}
                onCancel={() => setPremiumPreviewOpen(false)}
                footer={null}
            >
                <p>Prémiové zvýraznění odliší váš inzerát v seznamu výsledků vyhledávání. Zvýraznění je aktivní po celou dobu platnosti inzerátu.</p>
                <img className='premiumListImg' src={premiumPreviewLink} alt="premium preview" />
            </Modal>




        </div>
    )

}

export default Checkout