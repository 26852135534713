import React, { useState, useEffect } from 'react';
import { Form, Button } from 'antd';
import AppButton from '../helpers/AppButton';
import { useStore } from '../../store/useStore';
import PreviewGallery from './Preview/PreviewGallery';
import PhoneDevice from './Preview/PhoneDevice';
import ReadMore from './Preview/ReadMore';
import "./Preview/icons.css";
import { useNavigate } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';


const IconsGallery = ({ values, size }) => {
    if (!values) return null;
    const web = !!values.web;
    const facebook = !!values?.facebook ?? false;
    const instagram = !!values?.instagram??false;
    // const email = !!values.email;
    // const phone = !!values.phone;

    const phone = true;
    const email = true;
    // const instagram = true;

    const sizeIcon = "100%"
    const gapSize = Math.floor(size / 2) + "px"


    const iconsText = {
        phone: "Telefon",
        email: "Email",
        web: "Web",
        facebook: "Facebook",
        instagram: "Instagram"
    }

    const icons = { phone, email, web, facebook, instagram }
    let iconsList = [];

    for (const [key, value] of Object.entries(icons)) {
        if (value) {
            iconsList.push(key);
        }
    }


    return (
        <div className="iconsGallery" style={{ gap: gapSize }}>
            {iconsList.map((icon, index) => {
                return (
                    <div key={index} className={"fullIcon"} style={{ width: size }}>
                        <div className="circle">
                            <div key={index} className={"icon i-" + icon} style={{ width: sizeIcon, height: sizeIcon }}></div>
                        </div>
                        <div className="icon-text">{iconsText[icon]}</div>
                    </div>
                )
            })}
        </div>
    )
}



const Preview = ({values, ...rest}) => {
    // const form = Form.useFormInstance();
    const deviceRef = React.createRef();
    const [deviceHeight, setDeviceHeight] = useState(0);
    const [data, setData] = useState({});
    const { page, baseURL, setPage, getFormValues} = useStore();
    const [formValuesStore, setFormValues] = useState( getFormValues('business')??{}); //get initial values
    const formValues = values??formValuesStore;
    const navigate = useNavigate();


    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        //get "device" height 
        if (deviceRef.current) {
            setDeviceHeight(deviceRef.current.clientHeight);
        }

    }, [deviceRef.current, dimensions]);

    const fieldValues = formValues;
    // console.log("FieldValues: ", fieldValues);

    const baseSize = (deviceHeight / 70);
    const baseFontSize = (deviceHeight / 70);
    const titleFontSize = (baseFontSize * 1.8)
    const addrFontSize = (baseFontSize * 1.4)

    const address = (fieldValues['street'] ?? "Adresa") + ", " + (fieldValues["city"] ?? "Město");


    const mapImgUrl = baseURL+"/images/podklady/mapa-demo.png";

    return (
        <div className="pagePreview fullpage" >
            <div className="preview__header">
                <h1><b>Náhled</b> inzerátu</h1>
            </div>
            <div className="preview__device" ref={deviceRef} style={{ fontSize: baseFontSize }}>
                <PhoneDevice deviceHeight={deviceHeight}>
                    {(deviceHeight > 0) && (<>
                        <PreviewGallery />
                        <div className="titleStrip" style={{ fontSize: titleFontSize }}>
                            {fieldValues["businessName"] ?? "Název provozovny nebyl zadán"}
                        </div>
                        <div className="descriptionStrip">
                            <ReadMore maxSize="250" >{fieldValues["description"] ?? "Popis nebyl zadán, vložte jej prosím do formuláře"}</ReadMore>
                        </div>
                        <div className="iconStrip" >
                            <IconsGallery values={fieldValues} size={baseSize * 3} />
                        </div>
                        <div className='addressStrip' style={{ fontSize: addrFontSize }}>
                            <div className="address">
                                <div className="address__street">{address}</div>
                            </div>
                        </div>
                        <div className='mapStrip' style={{ fontSize: addrFontSize }}>
                            <img src={mapImgUrl} />
                        </div>
                    </>)}
                </PhoneDevice>
            </div>
            <div className="preview__footer buttons">
                <AppButton onClick={() => navigate("/form")}><LeftOutlined/> Upravit</AppButton> <AppButton type="primary" onClick={() => navigate("/checkout")}>K pokladně <RightOutlined/></AppButton>
            </div>
        </div>
    )


}

export default Preview