import React, { useRef } from 'react';
import { Input, Form } from 'antd';
// import { useStore } from '../../../store/useStore';
import PopTextarea from '../../helpers/PopTextarea';


const Description = () => {

    return (
        <div className="descriptionBlock">
            <Form.Item

                name="description"
                label="Popis"
                rules={[{ required: true, message: 'Něco o Váš napište ', minLength: 2 }]}
            >
                {/* <PopTextarea 
                    prefix='Popis *'
                    placeholder="Popis"
                    showCount
                    maxLength={250}                    
                    rules={[{ required: true, message: 'Něco o Váš napište ' }]}

                /> */}
                <Input.TextArea 
                    prefix='Popis *'
                    placeholder="Popis"
                    showCount
                    maxLength={250}                    
                    minRows={2}

                />

            </Form.Item>
        </div>
    )
}

export default Description
