import React, {useEffect} from 'react';
import { useStore } from "../../../store/useStore"
import {Carousel} from 'antd';

const Gallery = ({ images, realHeight }) => {
    const contentStyle = {
        height: realHeight,
        color: '#fff',
        width: '100%',
        background: '#364d79',
        overflow: 'hidden',
    };

    const imageStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    };

    return (
        <Carousel dots={{className:"galleryDots"}} dotPosition="top" style={{ width: '100%', height: '100%' }}>
            {images.map((image, index) => {
                console.log("Image:", image);
                const file = image.originFileObj??null;
                const objectURL = URL.createObjectURL(file);
                return (
                    <div >
                        <div key={index} style={contentStyle}>
                            <img
                                key={index}
                                src={objectURL}
                                alt={file.name}
                                style={imageStyle}
                            />
                        </div>
                    </div>
                );
            })}
        </Carousel>
    )
}


const PreviewGallery = () => {
    const store = useStore();
    const ref = React.useRef(null);
    const [realHeight, setRealHeight] = React.useState(null);
    // console.log("Store:", store);
    const images = store.fileList.filter((file) => (file.status === "done" && file.originFileObj !== undefined && file.response.status === true));

    useEffect(() => {
        // console.log("Ref:", ref);
        setRealHeight(ref.current.clientHeight);
    }, [ref]);

    return (
        <div style={{ width: '100%', height: '30%', overflow: 'hidden' }} className="overCarousel" ref={ref}>
            {(realHeight && (images.length > 0)) ? <Gallery images={images} realHeight={ref.current.clientHeight} /> : <p>Nejsou nahrány žádné obrázky</p>}
        </div>

    )
}


export default PreviewGallery