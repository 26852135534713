import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useStore } from '../../store/useStore';
import Images from './blocks/Images';
import { SessionExpiration } from '../SessionExpiration';
import AddressData from './blocks/AddressData';
import Description from './blocks/Description';
import { Form, message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Preview from './Preview';
import { PictogramIcon } from '../helpers/Pictograms';
import AppButton from '../helpers/AppButton';


const ImgsAddr = () => {
    // const { page, baseURL, setPage } = useStore();
    const ref = React.createRef();
    const refLast = React.createRef();
    const [form] = Form.useForm();
    const { storeFormValues, getFormValues, storeBackUrl } = useStore();
    const [rerender, setRerender] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    // console.log("Loc:",location)
    const currentUrl = location.pathname;

    // let mounted = true;

    // const onFinish = (values) => {
    //     console.log('Success:', values);
    //     storeFormValues('business', values);
    // };
    let timer = null;
    const maxDelay = 3000;
    const minDelay = 1000;
    let firstChange = null;


    const handleFormChange = (e) => {
        const values = form.getFieldsValue();
        //console.log('Form changed: ', values);
        storeFormValues('business', values);
        ref.current = values;
        clearTimeout(timer);
        if (!firstChange) { firstChange = Date.now(); }
        if (Date.now() - firstChange > maxDelay) {
            setRerender(rerender + 1);
            firstChange = null;
        } else {
            timer = setTimeout(() => {
                firstChange = null;
                setRerender(rerender + 1);
            }, minDelay);
        }
    }

    useEffect(() => {
        form.setFieldsValue(getFormValues('business'));
    }, [form]);



    const nextHandle = (target) => {
        form.validateFields().then((values) => {
            storeFormValues('business', values);
            // console.log('Storing backUrl: ', currentUrl);
            storeBackUrl(currentUrl);
            // console.log('Stored backUrl: ', currentUrl);
            if (!target) {
                // console.log('no target');
                navigate("/preview");
                // console.log('going to preview');
            } else {
                // console.log('going to ', target);
                navigate(target);
            }
        }).catch((info) => {
            // console.log('Validate Failed:', info);
            message.error('Některá pole nejsou vyplněna');
            // console.log('Validate Failed:', info);
            // for (const key in info) {
            //     if (Object.hasOwnProperty.call(info, key)) {
            //         const element = info[key];
            //         iconsole.log(key, element);
            //         message.error(element?.errors[0].message);
            //     }
            // }
        });
    }



    const layout = {
        labelCol: { span: 0 },
    };


    return (
        <div className="pageImgsAddr fullpage" >
            <Form form={form} layout="vertical" name="addressData" {...layout} onChange={handleFormChange} >
                <Images />
                <h1><PictogramIcon type='icon' value='pokracovat' asMask /><b>Vypiš</b> údaje</h1>
                <AddressData />
                <h1><PictogramIcon type='icon' value='pokracovat' asMask /><b>Napiš</b> popis</h1>
                <Description />
                <div className='buttons'>
                    <AppButton onClick={() => navigate("/")} ><LeftOutlined /> Zpět</AppButton>
                    <AppButton className="showPreview" type="primary" onClick={()=>nextHandle()}>Pokračovat <RightOutlined /></AppButton>
                </div>
                <div className='buttonsDesktop'>
                    <AppButton onClick={() => navigate("/")} size="large"><LeftOutlined /> Zpět</AppButton>
                    <AppButton 
                        className="goCheckout" type="primary" size="large" onClick={() => nextHandle('/checkout')}>K pokladně <RightOutlined /></AppButton>
                </div>
                {/* <SessionExpiration /> */}
            </Form>
            <div className="pageImgsAddr__preview">
                <Preview values={getFormValues('business')} />
            </div>
        </div>
    );

}


export default ImgsAddr;