import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useStore } from '../../store/useStore';
import { useNavigate } from "react-router-dom";
// import TiltedBlock from '../helpers/TiltedBlock';
import PulsatingBlock from '../helpers/PulsatingBlock';

const Intro = () => {
    const { page, baseURL, setPage } = useStore();
    const navigate = useNavigate();

 
    return (
        <div className="pageIntro fullpage" >
            <PulsatingBlock className="logo" src={baseURL + "/components/com_nakoleipesky/assets/images/svg/logo-z-pdf.svg"} >
            </PulsatingBlock>
            <div className="title" >
                <div>
                <img src={baseURL + "/components/com_nakoleipesky/assets/images/svg/title.svg"} alt="Nakole i Pesky" />
                </div>
            </div>
            <div className="subtitle">
                registrace do apky<br />
                ve 3 krocích.
                

            </div>
            {/* <div className='adStamp clipped'>
                <span>od&nbsp;<span>14Kč</span></span>
                <div>den</div>
            </div> */}
            <div className='buttons'>
                <Button type="primary" className="appButtonIntro" onClick={()=>navigate("/form")} size="large">Spustit konfigurátor</Button>
            </div>
            <div className='buttonsDesktop'>
                <Button type="primary" className="appButtonIntro"  onClick={()=>navigate("/form")} size="large" >Spustit konfigurátor</Button>
            </div>

            <div className="footer">
                <ul>
                    <li><a href="https://nakoleipesky.cz" >Provozovatel - nakoleipesky.cz</a></li>
                    <li><a href="https://konfigurator.nakoleipesky.cz/images/obchodni-podminky.pdf" target="_blank" >Obchodní podmínky</a></li>
                </ul>
            </div>
                    
        </div>
    );

}


export default Intro;