import Intro from './pages/Intro';
import ImgsAddr from './pages/ImgsAddr';
import Preview from './pages/Preview';
import Checkout from './pages/Checkout';
import Thankyou from './pages/Thankyou';

import {
    createBrowserRouter,
    createHashRouter,
    RouterProvider,
  } from "react-router-dom";

// import {useStore} from '../store/useStore';

const router = createHashRouter([
    {
      path: "/",
      element: <Intro />
    },
    { path: "/form", element: <ImgsAddr /> },
    { path: "/preview", element: <Preview /> },
    { path: "/checkout", element: <Checkout /> },
    { path: "/thankyou", element: <Thankyou /> },
  ]);

const PageRouter = () => {
    // const { page } = useStore();

    return <RouterProvider router={router} />
}


export default PageRouter;