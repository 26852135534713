import React, { useState } from 'react';
import { Button, Modal, Upload, Form } from 'antd';
import { PlusOutlined, CameraOutlined } from '@ant-design/icons';
import CustomUpload from './CustomUpload';
import { useStore } from '../../../store/useStore';
import { useSessionExpiration } from '../../../store/SessionExpirationContext';
import { PictogramIcon } from '../../helpers/Pictograms';

const style = {
    "width": "100%",
    "minHeight": "200px",
    "display": "flex",
    "justifyContent": "center",

}

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });


const UploadButton = () => (
    <div className="uploadButtonContent">
        {/* <PlusOutlined /> */}
        <PictogramIcon value="pokracovat" type="icon" size={32} />
        <div className='upload-button-text'
            style={{
                marginTop: 8,
                fontFamily: "Bebas Neue",
                
            }}
        >
            <b>Nahraj</b> fotky
        </div>
    </div>
);

const TakePhotoButton = () => (
    <div>
        <CameraOutlined />
        <div
            style={{
                marginTop: 8,
            }}
        >
            Vyfotit
        </div>
    </div>
);


const Images = () => {

    const { page, baseURL, apiBase, fileList, setFileList, form } = useStore();

    const { setSessionRemainingTime } = useSessionExpiration();

    const { Dragger } = Upload;

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    // const [fileList, setFileList] = useState([
    //     {
    //         uid: '-1',
    //         name: 'image.png',
    //         status: 'done',
    //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    //     },
    //     {
    //         uid: '-2',
    //         name: 'image.png',
    //         status: 'done',
    //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    //     },
    // ]);

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({ fileList: newFileList, file }) => {
        setFileList(newFileList);

        if (file.status === "done") {
            const response = file.response;
            if (response.sessionExpirationRemainingTime) {
                setSessionRemainingTime(response.sessionExpirationRemainingTime);
            }
            if (response.status == false) {
                Modal.error({
                    title: 'Chyba',
                    content: response.message,
                });
                //remove file from list
                setFileList(fileList.filter(f => f.uid !== file.uid));
            }
        }

    }

    const uploadProps = {
        action: apiBase + "/upload",
        listType: "picture-card",
        fileList: fileList,
        onPreview: handlePreview,
        onChange: handleChange,
        // extraButton: <TakePhotoButton />,
        withCredentials: true // Send cookies with the request
    }

    return <div className='imagesBlock'>
        <Form.Item
            name="images"
            label=""
        >
        <CustomUpload {...uploadProps} >
            {fileList.length >= 10
                ? null
                : (<>
                    <UploadButton />
                </>)
            }
        </CustomUpload>
        </Form.Item>        
        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
            <img
                alt="example"
                style={{
                    width: '100%',
                }}
                src={previewImage}
            />
        </Modal>
    </div>

}

export default Images;