import React, { useEffect, useState } from 'react';

const PulsatingBlock = ({src, ...props}) => {
 
    return (
        <>
        <div className='pulsatingBlock' {...props} style={{ ...props.style, animation: "pulsate 3s ease-in-out infinite",
        background: `url(${src}) no-repeat center center`,  backgroundSize: "contain"
    }}
        >
            {props.children}
        </div>
        </>
    );
};

export default PulsatingBlock;