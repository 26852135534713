// import React from 'react';
import { Upload, message } from 'antd';

const CustomUpload = (props) => {
    const { extraButton, fileList } = props;
    const allowedTypes = ['image/jpeg'];

    const renderItem = (originNode, file, fileList) => {
        // console.log(originNode, file, fileList);
        if (file.status === 'extraButton') {
            return (
                <div class="ant-upload ant-upload-select">
                    <span tabindex="0" class="ant-upload" role="button">
                        {extraButton}
                    </span>
                </div>
            );
        }
        if (props.itemRender) {
            return props.itemRender(originNode, file, fileList);
        }
        return originNode;
    };


    // Filter out the extraButton from the fileList
    const updatedFileList = fileList.filter((file) => file.status !== 'extraButton');

    const propsUpload = {
        ...props,
        fileList: extraButton ? [...updatedFileList, { uid: 'extraButton', status: 'extraButton' }] : fileList,
        itemRender: renderItem,
        // onChange({ file, fileList }) {
        //     if (file.status !== 'uploading' && file.status !== 'extraButton') {
        //         console.log(file, fileList);
        //     }
        // },
        onPreview: async (file) => {
            if (file.status === 'extraButton') {
                return;
            }
            if (props.onPreview) {
                props.onPreview(file);
                return;
            }
        },
        beforeUpload: (file) => {
            const isAllowed = allowedTypes.includes(file.type);
            if (!isAllowed) {
                message.error(`Formát souboru ${file.name} není podporován`);
            }
            return isAllowed || Upload.LIST_IGNORE;
          },                 
    };

    return <Upload {...propsUpload} >{props.children}</Upload>;
};

export default CustomUpload;