import { Select, Tag } from 'antd';
import { pictogramOptions, PictogramIcon } from './Pictograms';
import { useStore } from '../../store/useStore';

const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    // console.log("tagRender", props, value);
    return (
        <Tag
            icon={<PictogramIcon value={value} type="pictogram" />}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
                marginRight: 3,
            }}
        >
            {label}
        </Tag>
    );
};

const PictoSelect = (props) => (
    <Select
        className='pictoSelect'
        mode="multiple"
        showArrow
        tagRender={tagRender}
        options={pictogramOptions}
        {...props}
    />
);
export default PictoSelect;