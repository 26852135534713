import { Button } from 'antd';

export const AppButton = ({ children, ...props }) => {
    return (
        <Button {...props} className={"appButton " + props.className}>
            {children}
        </Button>
    );
}

export default AppButton;