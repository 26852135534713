import React, {useEffect, useState } from "react";
import { useStore } from "../../store/useStore";

const Thankyou = () => {
    const store = useStore();
    const { apiBase,baseURL } = store;
    const [result, setResult] = useState(null); // [state, setState
    const [error, setError] = useState(null); // [state, setState

    //order id from url params
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const refid = urlParams.get("refid");

    //Fetch data from API -- returns payment result
    const fetchPaymentResult = async () => {
        try {
            const response = await fetch(`${apiBase}/checkout_result`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ id, refid }),
            });
    
            if (!response.ok) {
                throw new Error("Server returned an error.");
            }
    
            const data = await response.json();
            return data;
        } catch (err) {
            setError(err.message);
            return null;
        }
    };

    useEffect(() => {
        store.clear();

        //Fetch data from API -- returns payment result
        refid && fetchPaymentResult().then((data) => {
            if (data) {
                console.log("data", data);
                setResult(data.html ? data.html : '');                
            }
        });
    }, []);

    return (
        <div className="fullpage thankyou">
        <h1>Děkujeme za odeslání objednávky</h1>
        {error && <p className="error-message">Došlo k chybě při zobrazovaní výsledků platby: {error}. Toto nemá vliv na Vaši objednávku nebo platbu.</p>}
        {result && (
            <div dangerouslySetInnerHTML={{ __html: result }}></div>
            )}
        <p>S dalšími informacemi se Vám brzy ozveme.</p>
        </div>
    );

}

export default Thankyou;